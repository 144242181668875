<template>
  <div></div>
</template>

<script>
export default {
  created () {
    window.location.href = '/404/'
  }
}
</script>
